<template>
  <div class="cat-student">
    <div>
      <div>
        Класс
        <select
          class="form-select light"
          v-model="selectedClassID"
          @change="getStudents(selectedClassID)"
          id="class"
        >
          <option value="all">Все</option>
          <option
            v-for="user in sClass"
            :key="user.className"
            v-bind:value="user"
          >
            {{ user.className }}
          </option>
        </select>
        <span
          >Выбрано: {{ selectedClassID.classID }}
          {{ selectedClassID.className }}</span
        >
      </div>

      <div v-if="selectedClass.classID === 'admin'">
        <div class="submit-form">
          <div class="form-group">
            <label for="className">Фамилия</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="FirstName"
            />
          </div>
          <div class="form-group">
            <label for="classLider">Имя</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="LastName"
            />
          </div>
          <div class="form-group">
            <div style="display: flex">
              <label for="classLider" style="padding-right: 20px">Пол</label>
              <!-- <input
              type="radio"
              class="form-control"
              required
              v-model="Surname"
            /> -->

              <input
                type="radio"
                value="М"
                v-model="Surname"
                style="width: 1em"
              />
              <label
                class="form-check-label"
                for="one"
                style="padding-right: 20px"
                >М</label
              >

              <input type="radio" value="Ж" v-model="Surname" />
              <label class="form-check-label" for="two">Ж</label>
            </div>
          </div>
          <div class="form-group">
            <label for="classLider">Дата поступления</label>
            <input
              type="date"
              class="form-control"
              required
              v-model="ReceiptDate"
            />
          </div>

          Категория
          <select class="form-select" v-model="Category">
            <option
              v-for="cat in sCategory"
              :key="cat.Category"
              v-bind:value="cat.Category"
            >
              {{ cat.Category }}
            </option>
          </select>
        </div>
        <br />
        <button @click="createStudent" class="btn btn-success">
          Создать ученика
        </button>
      </div>
      <div>
        <button @click="showHelpCat = !showHelpCat" class="btn btn-warning">
          Помощь в выборе категории
        </button>
      </div>
      <div>
        <button @click="qr = true" class="btn btn-secondary">QR-коды</button>
      </div>

      <div v-if="showHelpCat" class="helpCat">
        Помощь при выставлении категорий
        <table>
          <tr>
            <th>Категория</th>
            <th>Описание</th>
            <th>Вес (если имеет 2 категории сразу)</th>
          </tr>
          <tr>
            <td><b>СОП</b></td>
            <td>социально опасное положение</td>
            <td>4</td>
          </tr>
          <tr>
            <td><b>ОВЗ</b></td>
            <td>наличие соответствующей справки ОБЯЗАТЕЛЬНО</td>
            <td>10</td>
          </tr>
          <tr>
            <td><b>МО</b></td>
            <td>наличие справки ГСП</td>
            <td>6</td>
          </tr>
          <tr>
            <td><b>МД</b></td>
            <td>наличие удостоверения многодетной семьи</td>
            <td>5</td>
          </tr>
          <tr>
            <td><b>МД и МО</b></td>
            <td>наличие удостоверения многодетной семьи и справки ГСП</td>
            <td>7</td>
          </tr>
          <tr>
            <td><b>Платник</b></td>
            <td>наличие договора питания (без других категорий)</td>
            <td>1</td>
          </tr>
          <tr>
            <td><b>Инвалиды</b></td>
            <td>наличие соответствующей справки ОБЯЗАТЕЛЬНО</td>
            <td>9</td>
          </tr>
          <tr>
            <td><b>Счет поставщика</b></td>
            <td>
              категория, которой не проставляется питание. Дети согласованы со
              столовой и Горобец В.Г.
            </td>
            <td>1</td>
          </tr>
          <tr>
            <td><b>Отказ от питания</b></td>
            <td>наличие заявления родителя на отказ от питания</td>
            <td>2</td>
          </tr>
          <tr>
            <td><b>СВО</b></td>
            <td>
              Есть подтверждающий документ от военкомата, что родитель
              мобилизирован на специальную военную операцию
            </td>
            <td>8</td>
          </tr>
        </table>
      </div>
    </div>

    <div>
      <div class="myTable">
        <v-col>
          <v-text-field
            class="search-table"
            v-model="search"
            append-icon="mdi-magnify"
            label="Поиск (введите фамилию\категорию)"
          >
          </v-text-field>
          <v-data-table
            :headers="headers"
            :items="this.classList"
            :items-per-page="50"
            hide-default-footer
            :loading="loading"
            :search="search"
          >
            <template v-slot:[`item.index`]="{ index }">
              {{ index + 1 }}
            </template>
            <template v-slot:[`item.Category`]="{ item }">
              <div style="display: flex; justify-content: center">
                <div>{{ item.Category }}</div>

                <!-- <div class="poster">
                  <div class="descr">
                    Какой-то текст который должен быть в блоке<br />
                    <a href="#">Интересная ссылка</a>
                  </div>
                </div> -->
                <div
                  v-if="
                    item.Order != undefined && item.Order.cat === item.Category
                  "
                  style="display: flex"
                >
                  <div v-if="item.Order.Order.text != ''">
                    <details style="margin-left: 15px">
                      <summary>
                        <svg
                          version="1.0"
                          xmlns="http://www.w3.org/2000/svg"
                          width="20.000000pt"
                          height="20.000000pt"
                          viewBox="0 0 1280.000000 945.000000"
                          preserveAspectRatio="xMidYMid meet"
                        >
                          <metadata>
                            Created by potrace 1.15, written by Peter Selinger
                            2001-2017
                          </metadata>
                          <g
                            transform="translate(0.000000,945.000000) scale(0.100000,-0.100000)"
                            fill="green"
                            stroke="none"
                          >
                            <path
                              d="M9499 9382 c-31 -37 -181 -215 -334 -397 -1091 -1294 -2177 -2616
-3585 -4365 -746 -926 -1002 -1242 -1272 -1568 -442 -532 -664 -762 -738 -762
-31 0 -102 71 -195 195 -164 218 -370 557 -875 1440 -488 854 -655 1128 -752
1239 l-35 40 -92 -34 c-319 -115 -692 -349 -1061 -666 -187 -161 -560 -539
-560 -567 0 -40 577 -820 1199 -1622 761 -981 1385 -1736 1670 -2024 124 -125
276 -210 469 -263 105 -29 347 -31 472 -5 102 22 319 93 412 136 77 35 94 55
378 416 498 637 740 938 1100 1370 1848 2220 3966 4322 6242 6193 211 173 723
584 801 642 25 19 47 38 47 43 0 4 -15 7 -33 7 -55 0 -343 20 -517 36 -1030
93 -1937 289 -2670 578 -10 4 -33 -16 -71 -62z"
                            />
                          </g>
                        </svg>
                      </summary>

                      <div class="popover show bs-popover-right">
                        <div class="arrow"></div>
                        <h3 class="popover-header">
                          <div style="color: green" v-if="item.Order.cat">
                            {{ item.Order.cat }}
                          </div>
                        </h3>
                        <div class="popover-body">
                          <div class="data-tooltip" style="margin: -15px">
                            <div
                              v-if="item.Order != undefined"
                              style="font-size: 10px"
                            >
                              <div
                                style="color: green"
                                v-if="item.Order.documents"
                              >
                                Есть приказ на категорию
                              </div>

                              <div
                                style="color: green"
                                v-if="item.Order.Order.text != ''"
                              >
                                Приказ №{{ item.Order.Order.text }} &nbsp;от
                                &nbsp;{{
                                  new Date(
                                    item.Order.Order.date,
                                  ).toLocaleDateString()
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </details>
                  </div>

                  <div v-if="item.Order.documents">
                    <details style="margin-left: 15px">
                      <summary>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-clipboard-minus"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.5 9.5A.5.5 0 0 1 6 9h4a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5z"
                          />
                          <path
                            d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                          />
                          <path
                            d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                          />
                        </svg>
                      </summary>

                      <div class="popover show bs-popover-right">
                        <div class="arrow"></div>
                        <h3 class="popover-header">
                          <div style="color: green" v-if="item.Order.cat">
                            {{ item.Order.cat }}
                          </div>
                        </h3>
                        <div class="popover-body">
                          <div class="data-tooltip" style="margin: -15px">
                            <div
                              v-if="item.Order != undefined"
                              style="font-size: 10px"
                            >
                              <div
                                style="color: green"
                                v-if="item.Order.documents"
                              >
                                Документы сданы
                              </div>

                              <div
                                style="color: green"
                                v-if="item.Order.reference.text != ''"
                              >
                                Справка №{{
                                  item.Order.reference.text
                                }}
                                &nbsp;от &nbsp;{{
                                  new Date(
                                    item.Order.reference.date,
                                  ).toLocaleDateString()
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </details>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:[`item.violationsActions`]="{ item }">
              <div>
                <v-card-actions>
                  <button class="btn btn-warning" @click="showViolations(item)">
                    Показать
                  </button>
                  (в разработке)
                </v-card-actions>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <!-- v-if="selectedClass.classID === 'admin'" -->
              <div v-if="selectedClass.classID === 'admin'" class="select-flex">
                <div class="select">
                  <select
                    class="form-select"
                    name="marks"
                    v-bind:title="item.Category"
                    v-bind:id="item._id"
                  >
                    <option value=""></option>
                    <option v-for="caus in sCategory" :key="caus.Category">
                      {{ caus.Category }}
                    </option>
                  </select>
                </div>

                <v-card-actions>
                  <button class="btn btn-success" @click="updateCat(item._id)">
                    Обновить
                  </button>
                </v-card-actions>
              </div>
            </template>
            <template v-slot:[`item.dopObr`]="{ item }">
              <div class="select-flex" style="width: 150px">
                <v-card-actions>
                  <button
                    class="btn btn-outline-success"
                    @click="showModalClubs(item._id, item)"
                  >
                    Обновить ДО
                    <br />

                    <div
                      v-if="item.Clubs.length != 0"
                      style="
                        font-size: 10px;
                        padding-left: 5px;
                        padding-top: -2px;
                      "
                    >
                      Посещает: <b>{{ item.Clubs.length }} </b>
                    </div>
                  </button>
                </v-card-actions>
              </div>
            </template>
            <template v-slot:[`item.delete`]="{ item }">
              <div v-if="item.Clubs.length === 0" class="select-flex">
                <v-card-actions>
                  <button class="btn btn-danger" @click="deleteStudent(item)">
                    Удалить
                  </button>
                </v-card-actions>
              </div>
              <div v-else>
                <v-card-actions style="font-size: 10px; padding-left: 5px">
                  Для удаления нужно очистить занятость!
                </v-card-actions>
              </div>
            </template>
          </v-data-table>
        </v-col>
      </div>
    </div>

    <v-dialog v-model="qr" width="540px" height="95%">
      <div style="display: flex; flex-direction: column">
        <div style="background-color: aliceblue; position: fixed; width: 440px">
          <button @click="qr = false" class="btn btn-danger">
            QR-коды закрыть
          </button>
          <button class="btn btn-secondary" @click="newPrintStat()">
            Печать
          </button>
        </div>

        <div style="background-color: white; padding-top: 40px">
          <div id="tablePrint">
            <table id="table">
              <tr>
                <th>Ученик</th>
                <th>Код</th>
                <th>QR</th>
              </tr>
              <tr v-for="user in classList" :key="user._id">
                <td>{{ user.FirstName }} {{ user.LastName }}</td>
                <td>
                  {{ user._id }}
                </td>
                <td>
                  <VueQRCodeComponent
                    style="padding: 10px"
                    :text="user._id"
                    size="150"
                    color="#000000"
                    bg-color="#ffffff"
                    error-level="L"
                  ></VueQRCodeComponent>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </v-dialog>

    <v-dialog v-model="violationsDialog" width="90%" height="95%">
      Привет мир!
      <div style="background-color: aliceblue">
        {{ selectStudentID }}
      </div></v-dialog
    >

    <ClubsModal
      ref="ClubsModal"
      v-show="showModalCheck"
      @close="showModalClubs()"
      @refresh="refreshList()"
      :student="selectStudentID"
    />
  </div>
</template>

<style>
.cat-student {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.class-student {
  padding: 5px 10px 10px 10px;
  text-align: center;
  width: 350px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-radius: 3px;
  box-shadow: 0 0 3px #4d4d4d;
  margin-top: 10px;
}
</style>
<script>
import VueQRCodeComponent from "vue-qrcode-component";
import TutorialDataService from "../services/TutorialDataService";
import ClubsModal from "./ClubsModal.vue";
export default {
  components: {
    ClubsModal: ClubsModal,
    VueQRCodeComponent: VueQRCodeComponent,
  },
  props: {
    selectedClass: Object,
  },
  data() {
    return {
      qr: false,
      headers: [
        {
          value: "index",
          text: "№",
          width: "30px",
        },
        {
          text: "Класс",
          value: "className",
          sortable: false,
        },
        {
          text: "Фамилия",
          value: "FirstName",
          sortable: false,
        },
        {
          text: "Имя",
          value: "LastName",
          sortable: false,
        },

        { text: "Занятость", value: "dopObr", sortable: false },
        // {
        //   text: "Нарушения",
        //   value: "violationsActions",
        //   sortable: false,
        // },
        {
          text: "Категория",
          value: "Category",
          sortable: false,
        },
        { text: "Обновить категорию", value: "actions", sortable: false },
        {
          text: "Действия",
          value: "delete",
          sortable: false,
        },
      ],
      sClass: [{ className: "NO" }],
      sCategory: [{ Category: "1" }],
      classList: [{ FirstName: "TEST", Сlubs: [1] }],
      selectedClassID: "",
      classID: "",
      LastName: "",
      FirstName: "",
      Surname: "",
      ReceiptDate: "",
      Category: "",
      selectStudentID: "",
      showModalCheck: false,
      loading: false,
      showHelpCat: false,
      search: [],
      violationsDialog: false,
    };
  },

  methods: {
    initialization() {
      this.loading = true;
      this.clearFullData();
      this.retrieveTutorials();
      this.retrieveCategory();
      if (this.selectedClassID) {
        this.classList.splice(0);
        this.getStudents(this.selectedClassID);
      } else {
        this.classList.splice(0);
      }
      console.log(this.classList);
    },
    clearFullData() {
      this.classList.splice(0);
    },

    showViolations(item) {
      console.log(this.selectStudentID);
      this.selectStudentID = item;
      this.violationsDialog = !this.violationsDialog;
    },
    showModalClubs(studentID, item) {
      this.selectStudentID = studentID;
      this.showModalCheck = !this.showModalCheck;
      //this.$refs.ClubsModal.selectedClub = [];
      console.log(item, this.selectedClassID);

      this.$refs.ClubsModal.getStudents({ classID: item.classID });
    },
    newPrintStat() {
      //  let printHtml = document.getElementById("table").innerHTML; // Получаем содержимое узла для печати

      let tab = window.open(
        "",
        "",
        "left=50,top=50,width=800,height=640,toolbar=0,scrollbars=1,status=0",
      );
      tab.document.write(
        document.getElementById("tablePrint").innerHTML +
          "<style>table, td, th {border: 1px solid#ddd;text-align: left;}table {border-collapse: collapse;width: 100%;}th, td {padding: 15px;}</style>",
      );
      // tab.document.close();
      tab.focus();
    },
    deleteStudent(id) {
      console.log(id);

      let result = prompt(
        "Вы действительно хотите удалить ученика " +
          id.FirstName +
          " " +
          id.LastName +
          "? Введите фамилию ученика для удаления:",
        ["Фамилия"],
      );

      // confirm(
      //   "Вы действительно хотите удалить ученика " +
      //     id.FirstName +
      //     " " +
      //     id.LastName +
      //     "?"
      // );
      if (result == id.FirstName) {
        TutorialDataService.deleteStudent(id._id)
          .then(() => {
            this.initialization();
          })
          .catch((e) => {
            console.log(e);
          });

        this.initialization();
      }
    },

    async updateCat(ID) {
      var data = new Array();
      data[0] = document.getElementById(ID).value;

      await TutorialDataService.updateCat(ID, data)
        .then((response) => {
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
      this.initialization();
    },

    getStudents(data) {
      if (data === "all") {
        TutorialDataService.findStudentByClassID()
          .then((response) => {
            this.classList.splice(response.data);
            var a = new Array();
            a = Object.values(response.data);
            console.log(this.sClass);

            for (var i = 0; i < a.length; i++) {
              this.sClass.forEach((el) => {
                if (a[i].classID == el.classID) a[i].className = el.className;
              });
              this.$set(this.classList, i, a[i]);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        TutorialDataService.findStudentByClassID(data)
          .then((response) => {
            this.classList.splice(response.data);
            var a = new Array();
            a = Object.values(response.data);

            for (var i = 0; i < a.length; i++) {
              this.sClass.forEach((el) => {
                if (a[i].classID == el.classID) a[i].className = el.className;
              });
              this.$set(this.classList, i, a[i]);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },

    createStudent() {
      var data = {
        classID: this.selectedClassID.classID,
        LastName: this.LastName,
        FirstName: this.FirstName,
        Surname: this.Surname,
        // ReceiptDate: "2",
        Category: this.Category,
      };

      TutorialDataService.createStudent(data)
        .then((response) => {
          //this.sClass.id = response.data.id;
          console.log("УСПЕШНО ОТПРАВЛЕНО", response);
          this.refreshList();
        })
        .catch((e) => {
          console.log("1111111111", e);
        });
      this.initialization();
    },

    retrieveCategory() {
      TutorialDataService.getCategory()
        .then((response) => {
          this.sCategory = response.data.map(this.getDisplayCategory);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    retrieveTutorials() {
      this.loading = true;
      TutorialDataService.getAllCLass()
        .then((response) => {
          this.sClass = response.data.map(this.getDisplayTutorial);
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
        });

      if (this.selectedClass.classID != "admin") {
        this.selectedClassID = this.selectedClass;
        document.getElementById("class").disabled = true;

        this.getStudents(this.selectedClassID);
      }
    },
    refreshList() {
      this.loading = true;
      this.initialization();
    },

    getDisplayTutorial(data) {
      return {
        classID: data._id,
        className: data.className,
      };
    },

    getDisplayCategory(data) {
      return {
        Category: data.cat,
      };
    },
  },
  mounted() {
    console.log(this.selectedClass, "@@@@@@@@@@@@");
    this.retrieveTutorials();
    this.retrieveCategory();
  },
};
</script>

<style scoped type="text/css">
/* .poster {
  position: relative;

  background: #ff6600;
  height: 20px;
  width: 15px;
  z-index: 999;
}
.descr {
  display: none;
  margin-left: -350px;
  padding: 10px;
  margin-top: 17px;
  background: #f3f3f3;
  height: 200px;
  -moz-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
  z-index: 999999;
}
.poster:hover .descr {
  display: fixed;
  position: absolute;
  top: 12px;
  width: 400px;
} */
/* Убираем стандартный маркер Chrome */
details summary::-webkit-details-marker {
  display: none;
}
/* Убираем стандартный маркер Firefox */
details > summary {
  list-style: none;
}

details {
  position: relative;
  width: fit-content;
  height: fit-content;
}

.bs-popover-right {
  transform: translate(calc(20% + 0.1rem), 0);
  top: -65px;
  min-width: 200px;
  width: auto;
}

.bs-popover-right .arrow {
  top: 30%;
  margin: 0;
  transform: translate(0, -40%);
}

.bs-popover-top {
  transform: translate(0, calc(-50% - 0.3rem));
}

.bs-popover-top .arrow {
  left: 30%;
  transform: translate(50%, 0);
}
TABLE {
  width: auto; /* Ширина таблицы */
  border-collapse: collapse; /* Убираем двойные линии между ячейками */
  font-size: 12px;
  width: 300px;
}
TD,
TH {
  padding: 3px; /* Поля вокруг содержимого таблицы */
  border: 1px solid black; /* Параметры рамки */
}
TH {
  background: #b0e0e6; /* Цвет фона */
}
.search-table label {
  font-size: 10px;
}
#ulOrder {
  font-size: auto;
  margin: auto;
}
#ulOrder > li {
  font-size: auto;
}
</style>
